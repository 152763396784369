import {
  Component,
  ViewContainerRef,
  ViewChild,
  Inject,
  OnInit,
} from "@angular/core";
import { ApiService } from "./core/services/api.service";
import { AuthService } from "./core/services/auth.service";
import {
  Router,
  ROUTES,
  ActivatedRoute,
  ParamMap,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import { ComponentizerService } from "./core/services/componentizer.service";
import { DOCUMENT } from "@angular/platform-browser";
import { environment } from "../environments/environment";
import { LangService } from "./core/services/lang.service";
import { projectVars } from "./../environments/environment_vars";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  // providers: [{ provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader }]
})
export class AppComponent implements OnInit {
  // @ViewChild('mainOutlet', { read: ViewContainerRef }) mainOutlet: ViewContainerRef;

  cell: any;
  title = "cms";
  menus: any = [];
  lastMenuIdx: number = 0;
  environment = environment;

  constructor(
    @Inject(DOCUMENT) private document,
    public componentizer: ComponentizerService,
    public api: ApiService,
    public auth: AuthService,
    public router: Router,
    public langService: LangService
  ) {
    if (localStorage.lastMenuIdx)
      this.lastMenuIdx = parseInt(localStorage.lastMenuIdx);
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        for (let i in this.menus) {
          if (val.url == "/" + this.menus[i].alias) {
            localStorage.lastMenuIdx = parseInt(i);
          }
        }
      }
    });
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    let bases = this.document.getElementsByTagName("base");
    if (bases.length > 0) {
      bases[0].setAttribute("href", environment.baseHref);
    }

    if (projectVars.languages) {
      this.langService.languages = projectVars.languages;
    }
    this.langService.setDefaultLang("br");
  }
  ngAfterViewInit() {
    this.loadNavigation();
  }
  loadNavigation() {
    this.api.get("sys_menu").then((response) => {
      response.menus = [
        {
          id: "999",
          label: "Agendamentos",
          endpoint: null,
          alias: "agendamentos",
          page: "agendamentos",
          icon: null,
          security_level: null,
          read_only: "0",
          sort: null,
          visible: "1",
          menu_group: null,
          is_core: "0",
          has_modal: "1",
          features: null,
          extras: null,
          basicFilters: null,
          rootFilters: null,
        },
        ...response.menus,
      ];
      this.menus = response.menus;
      for (const i in response.menus) {
        this.setRouteConfig(response.menus[i]);
      }
    });
  }
  setRouteConfig(menu) {
    const pagePath =
      (menu.is_core == "1" ? "core" : "expendables") +
      "/pages/" +
      menu.page +
      "/" +
      menu.page +
      ".page";
    this.componentizer
      .componentize(pagePath, menu.page, "page", !menu.is_login)
      .then((component) => {
        let config = {
          path: menu.alias,
          component: component,
          data: menu,
          canActivate: [AuthService],
        };
        if (menu.is_login === true) {
          delete config.canActivate;
          if (!this.auth.isAuthenticated()) {
            this.router.navigate(["/" + menu.alias]);
          }
        }
        this.router.config.unshift(config);
        if (this.menus.indexOf(menu) === this.lastMenuIdx) {
          this.router.navigate(["/" + menu.alias]);
        }
      })
      .catch((e) => {
        console.warn("Erro na config da rota: " + menu.alias, { error: e });
      });
  }
}
